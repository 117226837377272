import { Component, createSignal, Show } from 'solid-js'
import { RoleSelectorProps } from '~/components/license-entry/license-entry.interfaces'
import { updateLicense } from '~/services/stripe/license'
import TogglePill from '~/components/toggle-pill/toggle-pill'
import { PillOption } from '~/components/toggle-pill/toggle-pill.interfaces'
import styles from '~/components/license-entry/license-entry.module.scss'
import CheckIcon from '~/assets/icons/check.svg'
import { uniqueLicenseRoles } from '~/static/stripe'
import { translations } from '~/translations'

const RoleSelector: Component<RoleSelectorProps> = ({ license }) => {
  const [showChangeConfirmation, setChangeConfirmation] = createSignal(false)

  const initialOptions = uniqueLicenseRoles.map(entry => ({
    ...entry,
    checked: license.role === entry.value
  }))
  const [options, setOptions] = createSignal<PillOption[]>(initialOptions)

  const setOptionsHandler = async (options: PillOption[]) => {
    setOptions(options)
    const role = options.find(entry => entry.checked)?.value === 'typesetting' ? 'typesetting' : 'translation'
    const result = await updateLicense({
      licenseId: license.id,
      role
    })
    if(result && 'success' in result){
      setChangeConfirmation(true)
      setTimeout(() => {
        setChangeConfirmation(false)
      }, 5000)
    }else{
      setOptions(initialOptions)
      alert(translations().subscriptions.pro.licenses.changeWarning)
    }
  }

  return (
    <div class={styles.roleSelector}>
      <Show when={showChangeConfirmation()}>
        <div class={styles.changeConfirmation}>
          <span class={styles.label}>{translations().general.actions.updated}</span>
          <CheckIcon class={styles.icon} />
        </div>
      </Show>
      <TogglePill 
        options={options()}
        setOptions={setOptionsHandler}
      />
    </div>
  )
}

export default RoleSelector
